/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
// Actions
import settings from './apiSettings.json';

let isLogoutOnce = false;

const capabilities = {
  ubnBdiRead: 'ubn_bdi_read',
  ubnBdiWrite: 'ubn_bdi_write',
  ubnItemRead: 'ubn_item_read',
  ubnItemWrite: 'ubn_item_write',
  ubnJobRead: 'ubn_job_read',
  ubnJobWrite: 'ubn_job_write',
  ubnItemGroupRead: 'ubn_item-group_read',
  ubnItemGroupWrite: 'ubn_item-group_write',
  ubnDataRead: 'ubn_data_read',
  ubnDataWrite: 'ubn_data_write',
  ubnItemTransferRead: 'ubn_item-transfer_read',
  ubnItemTransferWrite: 'ubn_item-transfer_write',
  ubnAgentInviteRead: 'ubn_agent-invite_read',
  ubnAgentInviteWrite: 'ubn_agent-invite_write',
  ubnAgentJoinRead: 'ubn_agent-join_read',
  ubnAgentJoinWrite: 'ubn_agent-join_write',
  ubnItemMorphRead: 'ubn_item-morph_read',
  ubnItemMorphWrite: 'ubn_item-morph_write',
  ubnShareRead: 'ubn_share_read',
  ubnShareWrite: 'ubn_share_write',
  ubnLcaRead: 'ubn_lca_read',
  ubnLcaWrite: 'ubn_lca_write',
  ubnSubscriptionsRead: 'ubn_subscriptions_read',
  ubnSubscriptionsWrite: 'ubn_subscriptions_write',
  ubnItemTemplatesRead: 'ubn_item-templates_read',
  ubnItemTemplatesWrite: 'ubn_item-templates_write',
  ubnDataTemplatesRead: 'ubn_data-templates_read',
  ubnDataTemplatesWrite: 'ubn_data-templates_write',
  ubnStatsRead: 'ubn_stats_read',
  ubnEventLogRead: 'ubn_event-log_read',
  sdcRead: 'sdc_read',
  ubnBdaRead: 'ubn_bda_read',
  ubnFoodForm: 'ubn_food_form',
};

const identifiersUrl = 'https://ib.unisot.id/';
const nkcUrl = 'http://www.nkc.no/track/';

const companyNames = {
  seafoodChain: 'SeafoodChain',
  unisot: 'UNISOT AS',
};

const isCapable = (profile, capability) => {
  if (profile && profile.capabilities) {
    return profile.capabilities.some((cap) => cap.name === capability);
  }
};

// return profile?.capabilities?.some((cap) => cap.name === capability);

const tokenStorage = {
  cookie: 'COOKIE',
  localStorage: 'LOCAL_STORAGE',
};
const tokenStorageType = tokenStorage.localStorage;

const methods = {
  get: 'GET',
  post: 'POST',
  delete: 'DELETE',
  patch: 'PATCH',
  put: 'PUT',
};

const contentTypes = {
  json: 'application/json',
  formData: 'form-data',
  searchParams: 'search-parameters',
  fileDownload: 'file-download',
  csv: 'csv',
};

const serviceTypes = {
  sdc: 'SDC',
  fdc: 'FDC',
  ubn: 'UBN',
  uie: 'UIE',
  paymail: 'PAYMAIL',
  topUp: 'TOP UP',
  soc: 'SOC',
};

const serviceSubscriptionPlans = {
  operation: 'PER OPERATION',
  monthly: 'MONTHLY',
  yearly: 'YEARLY',
};

const endpointTypes = {
  id: 'ID',
  sdc: 'SDC',
  fdc: 'FDC',
  ubn: 'UBN',
  imgur: 'IMGUR',
  elk: 'ELK',
  none: 'NONE',
  scm: 'SCM',
  csv: 'csv',
  xls: 'xls',
  package: 'PACKAGE',
};

const documentTypes = {
  avatar: 'AVATAR',
  logo: 'LOGO',
  userId: 'USER ID',
  companyDocuments: 'COMPANY DOCUMENTS',
  driversLicense: 'DRIVERS LICENSE',
  signature: 'SIGNATURE',
};

const documentCriteria = {
  documentId: 'byId',
  documentType: 'byType',
};

const jobStatus = {
  failed: 'FAILED',
  failedBlockchain: 'FAILED_BLOCKCHAIN',
  done: 'DONE',
};

const environmentEnum = {
  production: 'production',
  test: 'test',
  dev: 'dev',
  demo: 'demo',
  testAutomation: 'testAutomation',
};

const defaultRegistrationServices = [
  {
    serviceType: serviceTypes.sdc,
    subscriptionPlan: serviceSubscriptionPlans.operation,
    endpoints: [
      {
        name: serviceTypes.sdc,
        generateKeys: true,
      },
    ],
  },
  {
    serviceType: serviceTypes.topUp,
    subscriptionPlan: serviceSubscriptionPlans.operation,
    endpoints: [],
  },
  {
    serviceType: serviceTypes.soc,
    subscriptionPlan: serviceSubscriptionPlans.operation,
    endpoints: [],
  },
];

const defaultRegistrationRoles = ['id_user', 'sdc_user'];
let ApiStorage;
const setApiStorage = (apiStorage) => {
  ApiStorage = apiStorage;
};

// DEFAULT SETUP
const companyName = companyNames.unisot;
let showRegisterPage = true;
let provenanceFrontendURL = settings.provenanceFrontendURL_test;
let dcaFrontendURL = settings.dcaFrontendURL;
let { abendumURL } = settings;
let { trackingAppUrl } = settings;
let { socfrontendURL } = settings;
let ID = settings.idUnisotURL;
let SDC = settings.unisotSdcURL;
let FDC = settings.unisotFdcURL;
let ELK = settings.unisotElk;
let env = settings.INSTANCE_TYPE;

const setEnvironment = (instance) => {
  switch (instance) {
    case environmentEnum.dev:
      env = environmentEnum.dev;
      provenanceFrontendURL = settings.provenanceFrontendURL;
      abendumURL = settings.abendumURL;
      trackingAppUrl = settings.trackingAppUrl;
      socfrontendURL = settings.socfrontendURL;
      ID = settings.idUnisotURL;
      SDC = settings.unisotSdcURL;
      ELK = settings.unisotElk;
      showRegisterPage = true;
      break;
    case environmentEnum.test:
      env = environmentEnum.test;
      provenanceFrontendURL = settings.provenanceFrontendURL_test;
      trackingAppUrl = settings.trackingAppUrl;
      ID = settings.idUnisotURL_test;
      SDC = settings.unisotSdcURL_test;
      ELK = settings.unisotElk;
      showRegisterPage = true;
      break;
    case environmentEnum.demo:
      env = environmentEnum.demo;
      provenanceFrontendURL = settings.seafoodChainFrontendURL_demo;
      trackingAppUrl = settings.trackingAppUrl_demo;
      ID = settings.idUnisotURL_demo;
      SDC = settings.unisotSdcURL_demo;
      FDC = settings.unisotFdcURL_demo;
      ELK = settings.unisotElk;
      showRegisterPage = true;
      break;
    case environmentEnum.testAutomation:
      env = environmentEnum.testAutomation;
      provenanceFrontendURL = settings.provenanceFrontendURL_testAutomation;
      ID = settings.idUnisotURL_testAutomation;
      SDC = settings.unisotSdcURL_testAutomation;
      ELK = settings.unisotElk;
      break;
    case environmentEnum.production:
      env = environmentEnum.production;
      provenanceFrontendURL = settings.provenanceFrontendURL_production;
      abendumURL = settings.abendumURL_production;
      trackingAppUrl = settings.trackingAppUrl_production;
      socfrontendURL = settings.socfrontendURL_production;
      ID = settings.idUnisotURL_production;
      SDC = settings.unisotSdcURL_production;
      FDC = settings.fdcUrl_production;
      ELK = settings.unisotElk;
      dcaFrontendURL = settings.dcaFrontendURL_production;
      showRegisterPage = false;
      break;
    default:
      env = environmentEnum.dev;
      provenanceFrontendURL = settings.provenanceFrontendURL;
      abendumURL = settings.abendumURL;
      trackingAppUrl = settings.trackingAppUrl;
      socfrontendURL = settings.socfrontendURL;
      ID = settings.idUnisotURL;
      SDC = settings.unisotSdcURL;
      FDC = settings.unisotFdcURL_demo;
      ELK = settings.unisotElk;
      showRegisterPage = true;
      break;
  }
};

const environment = setEnvironment(settings.INSTANCE_TYPE);

const getEnvironment = () => ({
  env,
  provenanceFrontendURL,
  trackingAppUrl,
  socfrontendURL,
  abendumURL,
  ID,
  SDC,
  FDC,
  ELK,
  showRegisterPage,
});
const scmEndpoint = 'https://testsdd.unisot.id/api/';
const endpoints = {
  loginUser: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/login`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  listAllUsers: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/listAll`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  loginUserSCM: {
    method: methods.post,
    endpointType: endpointTypes.none,
    path: `${scmEndpoint}auth/init-login`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  loginSCM: {
    method: methods.post,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}auth/login/{{email}}/{{secret}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  logoutSCM: {
    method: methods.post,
    endpointType: endpointTypes.none,
    path: `${scmEndpoint}auth/logout`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getPublicKey: {
    method: methods.get,
    path: 'users/keys',
    pathname: 'users/keys',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
    endpointType: endpointTypes.id,
  },
  listAllCompaniesSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/list/all`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  listMyCompaniesSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/list/mine`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  listFromRelationsSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/list/from-relations`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  addCompanySCM: {
    method: methods.post,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/add`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  ensureCompanyExistsSCM: {
    method: methods.post,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/ensure-exist`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  proposeCompanyUpdateSCM: {
    method: methods.post,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/propose/{{relation_id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  approveCompanyUpdateSCM: {
    method: methods.put,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/propose/{{company_id}}/{{relation_id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  rejectCompanyUpdateSCM: {
    method: methods.delete,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/propose/{{company_id}}/{{relation_id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  getCompanyByIdSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/id/{{id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getCompanyUsersSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/id/{{id}}/users`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  updateCompanySCM: {
    method: methods.put,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/id/{{id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  deleteCompanySCM: {
    method: methods.delete,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/id/{{id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  listAllRelationsSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}relation/list/all`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  listAllReferencingRelationSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}relation/list/referencing`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  listMyRelationsSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}relation/list/mine`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  addSupplierRelationSCM: {
    method: methods.post,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}relation/add/supplier`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  approveRelationAddedByRecipientSCM: {
    method: methods.put,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}relation/respond/{{relation_id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  rejectRelationAddedByRecipientSCM: {
    method: methods.delete,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}relation/respond/{{relation_id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  getRelationByIdSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}relation/id/{{id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  updateRelationSCM: {
    method: methods.put,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}relation/id/{{id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  deleteRelationSCM: {
    method: methods.delete,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}relation/id/{{id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  getOwnProfileSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}users/me`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  listAllUsersSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}users/list/all`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  addUserSCM: {
    method: methods.post,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}users/add`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  ensureUsersWithEmailsExistSCM: {
    method: methods.post,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}users/ensure-exist`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getProfileByIdSCM: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}users/id/{{id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  updateUserSCM: {
    method: methods.put,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}users/id/{{id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  deleteUserSCM: {
    method: methods.delete,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}users/id/{{id}}`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  getCountriesList: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}data/countries`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getIndustriesList: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}data/industries`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  requestDueDiligence: {
    method: methods.post,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/id/{{id}}/diligence`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  checkCompanyDidDiligence: {
    method: methods.get,
    endpointType: endpointTypes.scm,
    path: `${scmEndpoint}company/id/{{id}}/diligence`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getBdoCsvReport: {
    method: methods.get,
    endpointType: endpointTypes.csv,
    path: `${scmEndpoint}report/company/{{id}}/bdo/csv`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getBdoExcelReport: {
    method: methods.get,
    endpointType: endpointTypes.xls,
    path: `${scmEndpoint}report/company/{{id}}/bdo/excel`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getExcelReport: {
    method: methods.get,
    endpointType: endpointTypes.xls,
    path: `${scmEndpoint}report/company/{{id}}/excel`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  didLoginUser: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/did/login`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getDidLoginChallenge: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/did/login`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getProfile: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/profile`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getAllUsers: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/keys/sdc`,
    pathname: 'users/keys/sdc',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getSdcProfilesBatch: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/keys/sdc/batch`,
    pathname: `users/keys/sdc/batch`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getKeysServer: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/keys/signingInfo`,
    pathname: `users/keys/signingInfo`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getUserKeys: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/keys`,
    pathname: `users/keys`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getUserKeyPermissions: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/keys/permissions`,
    pathname: `users/keys/permissions`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getPermissions: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/key/getUpdatePermissions/batch`,
    pathname: `users/key/getUpdatePermissions/batch`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  patchUserKeyPermissions: {
    method: methods.patch,
    endpointType: endpointTypes.id,
    path: `users/keys/permissions`,
    pathname: `users/keys/permissions`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  batchUserKeyPermissions: {
    method: methods.patch,
    endpointType: endpointTypes.id,
    path: `users/keys/permissions/batch`,
    pathname: `users/keys/permissions/batch`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },

  addUserKeys: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/keys`,
    pathname: `users/keys`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  deleteUserSingleKey: {
    method: methods.delete,
    endpointType: endpointTypes.id,
    path: `users/keys/single`,
    pathname: `users/keys/single`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  updateKeyPermissionsBatch: {
    method: methods.patch,
    endpointType: endpointTypes.id,
    path: `users/keys/permissions/batch`,
    pathname: `users/keys/permissions/batch`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  addMetadata: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `metadata`,
    pathname: `metadata`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getStats: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `stats`,
    pathname: `stats`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getExtendedStats: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `stats/extended`,
    pathname: `stats/extended`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getEvents: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `eventlog`,
    pathname: `eventlog`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  bdiMessages: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `bdi/messages`,
    pathname: `bdi/messages`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  bdiSend: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `bdi/send`,
    pathname: `bdi/send`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getInventory: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `trace/inventory`,
    pathname: `trace/inventory`,
    successStatuses: ['200'],
    errorStatuses: { 404: { message: "Item doesn't exist!" } },
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getLightWeightInventory: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `trace/inventory/light`,
    pathname: `trace/inventory/light`,
    successStatuses: ['200'],
    errorStatuses: { 404: { message: "Item doesn't exist!" } },
    populate: false,
    contentType: contentTypes.searchParams,
  },
  createGroup: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `trace/group`,
    pathname: `trace/group`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getGroups: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `trace/groups`,
    pathname: `trace/groups`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getGroup: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `trace/groups/{{txid}}`,
    pathname: `trace/groups/{{txid}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  addItemToGroup: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `trace/groups/{{txid}}/item`,
    pathname: `trace/groups/{{txid}}/item`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  deleteItemFromGroup: {
    method: methods.delete,
    endpointType: endpointTypes.ubn,
    path: `trace/groups/{{txid}}/item`,
    pathname: `trace/groups/{{txid}}/item`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  consume: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `trace/consume`,
    pathname: `trace/consume`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getDNA: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `trace/dna/{{itemId}}`,
    pathname: 'trace/dna/{{itemId}}',
    successStatuses: ['200'],
    errorStatuses: { 404: { message: "Item doesn't exist!" } },
    populate: true,
    contentType: contentTypes.searchParams,
  },
  createItem: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `trace/create`,
    pathname: `trace/create`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  transferItem: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `trace/transfer`,
    pathname: `trace/transfer`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  consolidateItem: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `trace/consolidate`,
    pathname: `trace/consolidate`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  addData: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `trace/adddata`,
    pathname: `trace/adddata`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  removeData: {
    method: methods.delete,
    endpointType: endpointTypes.ubn,
    path: `trace/adddata`,
    pathname: `trace/adddata`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  // merge & split
  splitItem: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `trace/split`,
    pathname: `trace/split`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  mergeItem: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `trace/merge`,
    pathname: `trace/merge`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getItemDetailsByTxid: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `trace/inventory/{{itemTxid}}`,
    pathname: `trace/inventory/{{itemTxid}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  geTwinByIdentifier: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `trace/identify/id/{{identifier}}`,
    pathname: `trace/identify/id/{{identifier}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  geTwinByGS1Identifier: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `trace/identify/gs1/{{identifier}}`,
    pathname: `trace/identify/gs1/{{identifier}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  // Tracking app
  trackingData: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `share/contents/{{trackingData}}`,
    pathname: `share/contents/{{trackingData}}`,
    successStatuses: ['200'],
    errorStatuses: {
      404: { message: 'This link is no longer available.' },
    },
    populate: true,
    contentType: contentTypes.searchParams,
  },
  trackingAnchorData: {
    method: methods.get,
    path: `share/byItem/{{trackingAnchorData}}/public`,
    successStatuses: ['200'],
    errorStatuses: {
      404: { message: 'This link is no longer available.' },
    },
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getSharedItemByResolvingType: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `share/byResolvingType/{{resolvingType}}/public`,
    pathname: `share/byResolvingType/{{resolvingType}}/public`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  // Anchors
  anchors: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `anchors`,
    pathname: `anchors`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  createAnchors: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `anchors`,
    pathname: `anchors`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getAnchor: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `anchors/{{anchorTxid}}`,
    pathname: `anchors/{{anchorTxid}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  setAnchor: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `anchors/{{anchorTxid}}`,
    pathname: `anchors/{{anchorTxid}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  getItemAnchors: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `anchors/item/{{itemId}}`,
    pathname: `anchors/item/{{itemId}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },

  // share item
  shareItem: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `share`,
    pathname: `share`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  shareItemTemplate: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `share/template`,
    pathname: `share/template`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  shareItemTemplateToSdc: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `share`,
    pathname: `share`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getSharingInfoByTxid: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `share/byItem/`,
    pathname: `share/byItem/`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getSendDataCount: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `storage/send/files/count`,
    pathname: `storage/send/files/count`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getSharingCountByItemid: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `share/shared/count`,
    pathname: `share/shared/count`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getSharedEntries: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `share/shared/entries`,
    pathname: `share/shared/entries`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  editShareItem: {
    method: methods.patch,
    endpointType: endpointTypes.ubn,
    path: `share/{{shareId}}`,
    pathname: `share/{{shareId}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  deleteShareItem: {
    method: methods.delete,
    endpointType: endpointTypes.ubn,
    path: `share/{{shareId}}`,
    pathname: `share/{{shareId}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  // templates
  getTemplates: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `templates/create/unisot`,
    pathname: `templates/create/unisot`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  saveTemplate: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `templates/create/unisot`,
    pathname: `templates/create/unisot`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getTemplateByDisplayName: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `templates/create/unisot/{{displayName}}`,
    pathname: `templates/create/unisot/{{displayName}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  filterTemplateByDisplayName: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `templates/create/unisot`,
    pathname: `templates/create/unisot`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  deleteTemplateByDisplayName: {
    method: methods.delete,
    endpointType: endpointTypes.ubn,
    path: `templates/create/unisot/{{displayName}}`,
    pathname: `templates/create/unisot/{{displayName}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  // data templates
  getDataTemplates: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `templates/data`,
    pathname: `templates/data`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  postDataTemplate: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `templates/data`,
    pathname: `templates/data`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  updateDataTemplate: {
    method: methods.put,
    endpointType: endpointTypes.ubn,
    path: `templates/data/{{id}}`,
    pathname: `templates/data/{{id}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  deleteDataTemplate: {
    method: methods.delete,
    endpointType: endpointTypes.ubn,
    path: `templates/data/{{id}}`,
    pathname: `templates/data/{{id}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  // SDC
  getSendData: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `storage/send/files`,
    pathname: `storage/send/files`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getPublicShareUser: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/publicShare`,
    pathname: `users/publicShare`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  uploadFile: {
    method: methods.post,
    endpointType: endpointTypes.fdc,
    path: `storage/store/file`,
    pathname: `storage/store/file`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.formData,
  },
  uploadForm: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `storage/store/form`,
    pathname: `storage/store/form`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  uploadFileBase64: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `storage/store/base64`,
    pathname: `storage/store/base64`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getDocumentDetails: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `storage/details/file`,
    pathname: 'storage/details/file',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  deleteUploadedFile: {
    method: methods.delete,
    endpointType: endpointTypes.sdc,
    path: `storage/delete/file`,
    pathname: `storage/delete/file`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  generateSignature: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `signature`,
    pathname: `signature`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  generateReport: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `signature/report`,
    pathname: `signature/report`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getAccessRights: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `accessRights/entries`,
    pathname: `accessRights/entries`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getAccessRightsCount: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `accessRights/count`,
    pathname: 'accessRights/count',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  addAccessRights: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `accessRights`,
    pathname: `accessRights`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  accessRightsSendEmail: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `accessRights/invite`,
    pathname: `accessRights/invite`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  deleteAccessRights: {
    method: methods.delete,
    endpointType: endpointTypes.sdc,
    path: `accessRights`,
    pathname: `accessRights`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  subscribe: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `subscriptions/subscribe`,
    pathname: `subscriptions/subscribe`,
    successStatuses: ['201', '200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  unsubscribe: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `subscriptions/unsubscribe`,
    pathname: `subscriptions/unsubscribe`,
    successStatuses: ['201', '200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getsubscriptions: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `subscriptions`,
    pathname: `subscriptions`,
    successStatuses: ['200'],
    errorStatuses: { 401: { message: "Subscription doesn't exist!" } },
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getLatestJobStatus: {
    method: methods.get,
    endpointType: endpointTypes.fdc,
    path: `job/{{jobId}}/latest`,
    pathname: `job/{{jobId}}/latest`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  prepareFileForDownload: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `storage/prepare/file`,
    pathname: `storage/prepare/file`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  downloadFile: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `storage/download/file`,
    pathname: `storage/download/file`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.fileDownload,
  },
  downloadBase64File: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `storage/download/base64`,
    pathname: `storage/download/base64`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  downloadBase64Report: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `signature/download/base64`,
    pathname: `storage/download/base64`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getPackageDetails: {
    method: methods.get,
    endpointType: endpointTypes.package,
    path: `{{dataLink}}`,
    pathname: `{{dataLink}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  getFormDetails: {
    method: methods.get,
    path: 'storage/details/file',
    pathname: 'storage/details/file',
    status: 200,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
    endpointType: endpointTypes.sdc,
  },
  checkValidPaymail: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `bsvalias/id/{{id}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  // Locations
  getLastLocations: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `trace/inventory/location`,
    pathname: `trace/inventory/location`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  // account management
  getPricingPlans: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/pricingPlans`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getUserSubscriptions: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/subscriptions`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  cancelUserSubscription: {
    method: methods.patch,
    endpointType: endpointTypes.id,
    path: `users/subscriptions/cancel`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  requestPricingPlanUpdate: {
    method: methods.patch,
    endpointType: endpointTypes.id,
    path: `users/profile/requestPricingPlan`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  generate2FASecret: {
    method: methods.patch,
    endpointType: endpointTypes.id,
    path: `users/profile/generate2FASecret`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  enable2FA: {
    method: methods.patch,
    endpointType: endpointTypes.id,
    path: `users/profile/enable2FA`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  disable2FA: {
    method: methods.patch,
    endpointType: endpointTypes.id,
    path: `users/profile/disable2FA`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  resetPassword: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/resetPassword/sendLink`,
    pathname: `users/resetPassword/sendLink`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  resetUserPassword: {
    method: methods.patch,
    path: 'users/resetPassword/{{userId}}/{{passwordResetSecret}}',
    pathname: 'users/resetPassword/{{userId}}/{{passwordResetSecret}}',
    errorStatuses: {},
    successStatuses: ['200'],
    contentType: contentTypes.json,
    populate: true,
    endpointType: endpointTypes.id,
  },
  sendValidateEmailLink: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/validateEmail/sendLink`,
    pathname: `users/validateEmail/sendLink`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  createUser: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/profile`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getUserBillings: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/billings`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getUserServices: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/services`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  logoutUserAllSessions: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/logoutAll`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  updateUser: {
    method: methods.patch,
    endpointType: endpointTypes.id,
    path: `users/profile`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  deleteUser: {
    method: methods.delete,
    endpointType: endpointTypes.id,
    path: `users/profile`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getAvailableOrganizations: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/availableOrganizations`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
  },
  getBatchLookup: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/lookups/batch/public`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getSingleLookup: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: 'users/lookups/single',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  upsertLookup: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: 'users/lookups',
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getUserDocuments: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/documents`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  upsertUserImage: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/documents/image`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.formData,
  },
  upsertUserImageBase64: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/documents/image/base64`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  upsertUserFile: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/documents/file`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.formData,
  },
  upsertUserFileBase64: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/documents/file/base64`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  readUserDocument: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/documents/{{documentCriteria}}/base64`,
    successStatuses: ['200'],
    errorStatuses: {},
    contentType: contentTypes.searchParams,
    populate: true,
  },
  deleteUserDocument: {
    method: methods.delete,
    endpointType: endpointTypes.id,
    path: `users/documents/{{documentCriteria}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    contentType: contentTypes.json,
    populate: true,
  },
  requestDocumentsValidation: {
    method: methods.patch,
    endpointType: endpointTypes.id,
    path: `users/profile/requestDocumentsValidation`,
    successStatuses: ['200'],
    errorStatuses: {},
    contentType: contentTypes.json,
    populate: false,
  },
  getAsfis: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `asfis/search/{{q}}`,
    pathname: `asfis/search`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getLca: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `lca/data`,
    pathname: `lca/data`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  saveLca: {
    method: methods.post,
    endpointType: endpointTypes.ubn,
    path: `lca/data`,
    pathname: `lca/data`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  updateLca: {
    method: methods.put,
    endpointType: endpointTypes.ubn,
    path: `lca/data/{{id}}`,
    pathname: `lca/data/{{id}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.json,
  },
  deleteLca: {
    method: methods.delete,
    endpointType: endpointTypes.ubn,
    path: `lca/data/{{id}}`,
    pathname: `lca/data/{{id}}`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.searchParams,
  },
  elkSearch: {
    method: methods.post,
    endpointType: endpointTypes.elk,
    path: `elk/search`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  downloadReport: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `signature/download/file`,
    pathname: 'signature/download/file',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.fileDownload,
  },
  fetchDid: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `test/user/wallet/fetchDid`,
    pathname: `test/user/wallet/fetchDid`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  uploadLookupImage: {
    method: methods.post,
    endpointType: endpointTypes.fdc,
    path: `lookup/file`,
    pathname: `lookup/file`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.formData,
  },
  uploadLookupImageBase64: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `lookup/file/base64`,
    pathname: `lookup/file/base64`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  upsertPublicLookup: {
    method: methods.post,
    endpointType: endpointTypes.id,
    path: `users/lookups/single/public`,
    pathname: `users/lookups/single/public`,
    successStatuses: ['201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getDeployments: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/deployments`,
    pathname: `users/deployments`,
    successStatuses: ['200', '201'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getSinglePublicLookup: {
    method: methods.get,
    endpointType: endpointTypes.id,
    path: `users/lookups/single/public`,
    pathname: `users/lookups/single/public`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  productCertificate: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `share/certificate/{{itemId}}/public`,
    pathname: `share/certificate/{{itemId}}/public`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.fileDownload,
  },
  snapshotCert: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: 'share/snapshotCertificate/{{shareId}}/{{shareSecret}}',
    pathname: 'share/snapshotCertificate/{{shareId}}/{{shareSecret}}',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: true,
    contentType: contentTypes.fileDownload,
  },
  getUserExists: {
    method: methods.get,
    path: 'users/keys/sdc/exists',
    pathname: 'users/keys/sdc/exists',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
    endpointType: endpointTypes.id,
  },
  createGuestUser: {
    method: methods.post,
    path: 'users/profile',
    pathname: 'users/profile',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
    endpointType: endpointTypes.id,
  },
  inviteAccessRights: {
    method: methods.post,
    path: 'accessRights/invite',
    pathname: 'accessRights/invite',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
    endpointType: endpointTypes.sdc,
  },
  addSignature: {
    method: methods.post,
    path: 'signature',
    pathname: 'signature',
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
    endpointType: endpointTypes.sdc,
  },
  getIotConnections: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `connections/list`,
    pathname: `connections/list`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getIotSettings: {
    method: methods.get,
    endpointType: endpointTypes.ubn,
    path: `settings/list`,
    pathname: `settings/list`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  // fdca
  getTasks: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `tasks`,
    pathname: `tasks`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  // task templates
  getTaskTemplates: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `taskTemplates`,
    pathname: `taskTemplates`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  createTaskTemplate: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `taskTemplates`,
    pathname: `taskTemplates`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  editTaskTemplate: {
    method: methods.patch,
    endpointType: endpointTypes.sdc,
    path: `taskTemplates`,
    pathname: `taskTemplates`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  deleteTaskTemplate: {
    method: methods.delete,
    endpointType: endpointTypes.sdc,
    path: `taskTemplates`,
    pathname: `taskTemplates`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  createJobTemplate: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `jobTemplates`,
    pathname: `jobTemplates`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  deleteJobTemplate: {
    method: methods.delete,
    endpointType: endpointTypes.sdc,
    path: `jobTemplates`,
    pathname: `jobTemplates`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  updateJobTemplate: {
    method: methods.patch,
    endpointType: endpointTypes.sdc,
    path: `jobTemplates`,
    pathname: `jobTemplates`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  addTaskTemplateToJobTemplate: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `jobTemplates/taskTemplates`,
    pathname: `jobTemplates/taskTemplates`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  removeTaskTemplateFromJobTemplate: {
    method: methods.delete,
    endpointType: endpointTypes.sdc,
    path: `jobTemplates/taskTemplates`,
    pathname: `jobTemplates/taskTemplates`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  getJobTemplates: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `jobTemplates`,
    pathname: `jobTemplates`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getJobs: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `jobs`,
    pathname: `jobs`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  getSingleJob: {
    method: methods.get,
    endpointType: endpointTypes.sdc,
    path: `jobs/single`,
    pathname: `jobs/single`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  createJob: {
    method: methods.post,
    endpointType: endpointTypes.sdc,
    path: `jobs/jobTemplate`,
    pathname: `jobs/jobTemplate`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  deleteJob: {
    method: methods.delete,
    endpointType: endpointTypes.sdc,
    path: `jobs`,
    pathname: `jobs`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.searchParams,
  },
  updateJob: {
    method: methods.patch,
    endpointType: endpointTypes.sdc,
    path: `jobs`,
    pathname: `jobs`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
  updateTask: {
    method: methods.patch,
    endpointType: endpointTypes.sdc,
    path: `tasks`,
    pathname: `tasks`,
    successStatuses: ['200'],
    errorStatuses: {},
    populate: false,
    contentType: contentTypes.json,
  },
};

const getRedirectUri = async (
  uri,
  serviceType = undefined,
  name = undefined,
  userId = undefined,
  superuser = false
) => {
  let redirectUri = uri;
  const queryParams = {};
  let paramSet = false;

  if ([serviceTypes.sdc, serviceTypes.ubn].includes(serviceType)) {
    redirectUri = uri.endsWith('/') ? uri : `${uri}/`;
    redirectUri = `${redirectUri}auth/redirect-page`;
    if (serviceType === serviceTypes.ubn && name) {
      queryParams.name = name;
      paramSet = true;
    }
  }
  if (superuser && userId) {
    queryParams.userId = userId;
    paramSet = true;
  }

  if (tokenStorageType === tokenStorage.localStorage) {
    const userData = await ApiStorage.getSessionToken();
    const transformedData = JSON.parse(userData);
    const { authToken } = transformedData;

    if (authToken) {
      queryParams.authToken = authToken;
      paramSet = true;
    }
  }
  if (paramSet) {
    const formattedQueryParams = new URLSearchParams(queryParams).toString();
    redirectUri = `${redirectUri}?${formattedQueryParams}`;
  }
  return redirectUri;
};

const populatePath = (urlPath, params) => {
  let populatedPath = urlPath;
  const processingParameters = Object.entries(params);
  const paramsToProcess = {};
  for (const [key, value] of processingParameters) {
    const token = `{{${key}}}`;
    if (urlPath.includes(token)) {
      populatedPath = populatedPath.split(token).join(value);
    } else {
      paramsToProcess[key] = value;
    }
  }
  return {
    populatedPath,
    paramsToProcess,
  };
};

const fetchData = async (
  endpoint,
  params = {},
  signal = undefined,
  authorize = true,
  exportToken = false,
  tokenSCM = false
) => {
  const {
    method,
    endpointType,
    path,
    pathname,
    successStatuses,
    errorStatuses,
    contentType,
    populate,
  } = endpoint;

  const request = {
    method,
  };
  if (signal) {
    request.signal = signal;
  }
  const envUrl = (await ApiStorage.getEnvStorage()) || getEnvironment();
  let populatedPath =
    endpointType === endpointTypes.package ||
    endpointType === endpointTypes.none ||
    endpointType === endpointTypes.scm ||
    endpointType === endpointTypes.csv ||
    endpointType === endpointTypes.xls
      ? path
      : `${envUrl[endpointType]}api/${path}`;
  let userData;

  if (authorize) {
    userData = await ApiStorage.getSessionToken();
    const transformedData = JSON.parse(userData);
    if (transformedData && transformedData.backend) {
      if (endpointType === endpointTypes.ubn) {
        populatedPath = `${transformedData.backend.backendUri}/api/v4/${pathname}`;
      }
    }
  }

  let paramsToProcess = params;
  if (populate) {
    const pathPopulatingResult = populatePath(populatedPath, params);
    populatedPath = pathPopulatingResult.populatedPath;
    paramsToProcess = pathPopulatingResult.paramsToProcess;
  }
  const url = new URL(populatedPath);

  // Set headers
  const headers = {};
  if (contentType) {
    if (contentType === contentTypes.json) {
      headers['Content-Type'] = contentType;
      request.body = JSON.stringify(paramsToProcess);
    } else if (contentType === contentTypes.formData) {
      const formData = new FormData();
      for (const [key, value] of Object.entries(paramsToProcess)) {
        formData.append(key, value);
      }
      request.body = formData;
    } else if (
      contentType === contentTypes.searchParams ||
      contentType === contentTypes.fileDownload
    ) {
      Object.keys(paramsToProcess).forEach((key) => {
        if (key === 'query') {
          url.searchParams.append(key, JSON.stringify(params[key]));
        } else {
          url.searchParams.append(key, params[key]);
        }
      });
    }
  }

  if (tokenStorageType === tokenStorage.cookie && authorize) {
    request.credentials = 'include';
  } else if (tokenStorageType === tokenStorage.localStorage) {
    if (authorize) {
      const userData = await ApiStorage.getSessionToken();
      const transformedData = JSON.parse(userData);
      const { authToken } = transformedData;
      headers.authorization = `Bearer ${authToken}`;
    }
  }
  if (Object.keys(headers).length > 0) {
    request.headers = headers;
  }

  let response;
  response = await fetch(url, request);

  let responseJson;
  const successResponse = successStatuses.includes(response.status.toString());

  try {
    // if (true) {
    if (response.status === 498 || response.status === 499) {
      await ApiStorage.logoutUser();
      return;
    }
    if (response.status === 204) return '';
    if (endpoint.contentType === contentTypes.fileDownload) {
      responseJson = await response.blob();
    } else {
      if (endpointType === endpointTypes.none) return '';
      if (endpointType === endpointTypes.csv) return await response.text();
      if (endpointType === endpointTypes.xls) return await response.blob();

      responseJson = await response.json();
    }
    if (
      responseJson.code === 401 &&
      (responseJson.error === 'Please authenticate.' || responseJson.error === 'Unauthorized') &&
      !isLogoutOnce
    ) {
      isLogoutOnce = true;
      ApiStorage.logoutUser();
    }
  } catch (error) {
    if (error.message === "JSON Parse error: Unrecognized token '<'") {
      throw Error('Server is down!');
    }
    if (responseJson.code !== 200) {
      throw Error(error.message);
    }
  }
  if (!successResponse) {
    const errorMessage = errorStatuses[response.status];
    if (errorMessage) {
      throw Error(errorMessage.message);
    }
    throw Error(
      responseJson.message
        ? responseJson.message
        : responseJson.error
        ? responseJson.error
        : 'undefined error'
    );
  }

  if (exportToken) {
    // store token when login
    try {
      // const expirationTokenDate = new Date(new Date().getTime() + parseInt(decoded.exp));
      const backend = responseJson.user.endpoints.find(
        (endpoint) => endpoint.service === 'UBN' && !endpoint.blocked
      );
      const jsonValue = JSON.stringify({
        authToken: responseJson.token,
        userProfile: responseJson.user,
        // expiryTime: decoded.exp,
        // expiryDate: expirationTokenDate.toISOString()
        backend,
      });
      await ApiStorage.setSessionToken(jsonValue);
    } catch (error) {
      throw Error(error.message);
    }
  }

  if (tokenSCM) {
    try {
      const jsonValue = JSON.stringify({
        authToken: responseJson.token,
      });
      await ApiStorage.setSessionToken(jsonValue);
    } catch (error) {
      throw Error(error.message);
    }
  }

  return responseJson;
};
const queryBackendInterval = 500;

const queryLatestJobStatus = async (endpoint, params, signal, authorize = true) => {
  // eslint-disable-next-line no-promise-executor-return
  await new Promise((r) => setTimeout(r, queryBackendInterval));
  const responseData = await fetchData(endpoint, params, signal, authorize);
  return responseData;
};

const APIService = {
  loginUser: async (params, url, signal = undefined) => {
    if (url) endpoints.loginUser.path = url;
    const responseData = await fetchData(endpoints.loginUser, params, signal, false, true);
    return responseData;
  },
  listAllUsers: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.listAllUsers, params, signal);
    return responseData;
  },
  loginUserSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.loginUserSCM, params, signal, false, false);
    return responseData;
  },
  loginSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.loginSCM, params, signal, false, false, true);
    return responseData;
  },
  logoutSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.logoutSCM, params, signal);
    return responseData;
  },
  getPublicKey: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getPublicKey, params, signal);
    if (responseData.length === 0) {
      throw new Error('User does not have registered key');
    }
    return responseData[0];
  },
  listAllCompaniesSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.listAllCompaniesSCM, params, signal);
    return responseData;
  },
  listMyCompaniesSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.listMyCompaniesSCM, params, signal);
    return responseData;
  },
  listFromRelationsSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.listFromRelationsSCM, params, signal);
    return responseData;
  },
  addCompanySCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.addCompanySCM, params, signal);
    return responseData;
  },
  ensureCompanyExistsSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.ensureCompanyExistsSCM, params, signal);
    return responseData;
  },
  proposeCompanyUpdateSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.proposeCompanyUpdateSCM, params, signal);
    return responseData;
  },
  approveCompanyUpdateSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.approveCompanyUpdateSCM, params, signal);
    return responseData;
  },
  rejectCompanyUpdateSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.rejectCompanyUpdateSCM, params, signal);
    return responseData;
  },
  getCompanyByIdSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getCompanyByIdSCM, params, signal);
    return responseData;
  },
  getCompanyUsersSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getCompanyUsersSCM, params, signal);
    return responseData;
  },
  updateCompanySCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.updateCompanySCM, params, signal);
    return responseData;
  },
  deleteCompanySCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.deleteCompanySCM, params, signal);
    return responseData;
  },
  listAllRelationsSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.listAllRelationsSCM, params, signal);
    return responseData;
  },
  listAllReferencingRelationSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.listAllReferencingRelationSCM, params, signal);
    return responseData;
  },
  listMyRelationsSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.listMyRelationsSCM, params, signal);
    return responseData;
  },
  addSupplierRelationSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.addSupplierRelationSCM, params, signal);
    return responseData;
  },
  approveRelationAddedByRecipientSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(
      endpoints.approveRelationAddedByRecipientSCM,
      params,
      signal
    );
    return responseData;
  },
  rejectRelationAddedByRecipientSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(
      endpoints.rejectRelationAddedByRecipientSCM,
      params,
      signal
    );
    return responseData;
  },
  getRelationByIdSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getRelationByIdSCM, params, signal);
    return responseData;
  },
  updateRelationSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.updateRelationSCM, params, signal);
    return responseData;
  },
  deleteRelationSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.deleteRelationSCM, params, signal);
    return responseData;
  },
  getOwnProfileSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getOwnProfileSCM, params, signal);
    return responseData;
  },
  listAllUsersSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.listAllUsersSCM, params, signal);
    return responseData;
  },
  addUserSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.addUserSCM, params, signal);
    return responseData;
  },
  ensureUsersWithEmailsExistSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.ensureUsersWithEmailsExistSCM, params, signal);
    return responseData;
  },
  getProfileByIdSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getProfileByIdSCM, params, signal);
    return responseData;
  },
  updateUserSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.updateUserSCM, params, signal);
    return responseData;
  },
  deleteUserSCM: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.deleteUserSCM, params, signal);
    return responseData;
  },
  getCountriesList: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getCountriesList, params, signal);
    return responseData;
  },
  getIndustriesList: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getIndustriesList, params, signal);
    return responseData;
  },
  requestDueDiligence: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.requestDueDiligence, params, signal);
    return responseData;
  },
  checkCompanyDidDiligence: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.checkCompanyDidDiligence, params, signal);
    return responseData;
  },
  getBdoCsvReport: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getBdoCsvReport, params, signal);
    return responseData;
  },
  getBdoExcelReport: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getBdoExcelReport, params, signal);
    return responseData;
  },
  getExcelReport: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getExcelReport, params, signal);
    return responseData;
  },
  didLoginUser: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.didLoginUser, params, signal, false, true);
    return responseData;
  },
  getDidLoginChallenge: async (params, signal = undefined) => {
    const responseData = await fetchData(
      endpoints.getDidLoginChallenge,
      params,
      signal,
      false,
      false
    );
    return responseData;
  },
  getProfile: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getProfile, params, signal);
    return responseData;
  },
  getAllUsers: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getAllUsers, params, signal);
    return responseData;
  },
  getSdcProfilesBatch: async (params) => {
    const responseData = await fetchData(endpoints.getSdcProfilesBatch, params);
    return responseData;
  },
  getKeysServer: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getKeysServer, params, signal, false, false);
    return responseData;
  },
  getUserKeys: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getUserKeys, params, signal);
    return responseData;
  },
  getUserKeyPermissions: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getUserKeyPermissions, params, signal);
    return responseData;
  },
  getPermissions: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getPermissions, params, signal);
    return responseData;
  },
  patchUserKeyPermissions: async (params) => {
    const responseData = await fetchData(endpoints.patchUserKeyPermissions, params);
    return responseData;
  },
  batchUserKeyPermissions: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.batchUserKeyPermissions, params, signal);
    return responseData;
  },

  addUserKeys: async (params) => {
    const responseData = await fetchData(endpoints.addUserKeys, params);
    return responseData;
  },
  deleteUserSingleKey: async (params) => {
    const responseData = await fetchData(endpoints.deleteUserSingleKey, params);
    return responseData;
  },
  updateKeyPermissionsBatch: async (params) => {
    const responseData = await fetchData(endpoints.updateKeyPermissionsBatch, params);
    return responseData;
  },
  addMetadata: async (params) => {
    const responseData = await fetchData(endpoints.addMetadata, params);
    return responseData;
  },
  getStats: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getStats, params, signal);
    return responseData;
  },
  getExtendedStats: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getExtendedStats, params, signal);
    return responseData;
  },
  getEvents: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getEvents, params, signal);
    return responseData;
  },
  bdiMessages: async (params) => {
    const responseData = await fetchData(endpoints.bdiMessages, params);
    return responseData;
  },
  bdiSend: async (params) => {
    const responseData = await fetchData(endpoints.bdiSend, params);
    return responseData;
  },
  getInventory: async (params) => {
    const responseData = await fetchData(endpoints.getInventory, params);
    return responseData;
  },
  getLightWeightInventory: async (params) => {
    const responseData = await fetchData(endpoints.getLightWeightInventory, params);
    return responseData;
  },
  createGroup: async (params) => {
    const responseData = await fetchData(endpoints.createGroup, params);
    return responseData;
  },
  getGroups: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getGroups, params, signal);
    return responseData;
  },
  getGroup: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getGroup, params, signal);
    return responseData;
  },
  addItemToGroup: async (params) => {
    const responseData = await fetchData(endpoints.addItemToGroup, params);
    return responseData;
  },
  deleteItemFromGroup: async (params) => {
    const responseData = await fetchData(endpoints.deleteItemFromGroup, params);
    return responseData;
  },
  consume: async (params) => {
    const responseData = await fetchData(endpoints.consume, params);
    return responseData;
  },
  getDNA: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getDNA, params, signal);
    return responseData;
  },
  getItemDetailsByTxid: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getItemDetailsByTxid, params, signal);
    return responseData;
  },
  geTwinByIdentifier: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.geTwinByIdentifier, params, signal);
    return responseData;
  },
  geTwinByGS1Identifier: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.geTwinByGS1Identifier, params, signal);
    return responseData;
  },
  createItem: async (params) => {
    const responseData = await fetchData(endpoints.createItem, params);
    return responseData;
  },
  transferItem: async (params) => {
    const responseData = await fetchData(endpoints.transferItem, params);
    return responseData;
  },
  consolidateItem: async (params) => {
    const responseData = await fetchData(endpoints.consolidateItem, params);
    return responseData;
  },
  addData: async (params) => {
    const responseData = await fetchData(endpoints.addData, params);
    return responseData;
  },
  removeData: async (params) => {
    const responseData = await fetchData(endpoints.removeData, params);
    return responseData;
  },
  // merge & split
  splitItem: async (params) => {
    const responseData = await fetchData(endpoints.splitItem, params);
    return responseData;
  },
  mergeItem: async (params) => {
    const responseData = await fetchData(endpoints.mergeItem, params);
    return responseData;
  },
  // tracking app Data
  trackingData: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.trackingData, params, signal, false, false);
    return responseData;
  },
  trackingAnchorData: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.trackingAnchorData, params, signal, false);
    return responseData;
  },
  getSharedItemByResolvingType: async (params) => {
    const responseData = await fetchData(
      endpoints.getSharedItemByResolvingType,
      params,
      false,
      false
    );
    return responseData;
  },
  // Anchors
  anchors: async (params) => {
    const responseData = await fetchData(endpoints.anchors, params);
    return responseData;
  },
  createAnchors: async (params) => {
    const responseData = await fetchData(endpoints.createAnchors, params);
    return responseData;
  },
  getAnchor: async (params) => {
    const responseData = await fetchData(endpoints.getAnchor, params);
    return responseData;
  },
  setAnchor: async (params) => {
    const responseData = await fetchData(endpoints.setAnchor, params);
    return responseData;
  },
  getItemAnchors: async (params) => {
    const responseData = await fetchData(endpoints.getItemAnchors, params);
    return responseData;
  },

  shareItem: async (params) => {
    const responseData = await fetchData(endpoints.shareItem, params);
    return responseData;
  },
  shareItemTemplate: async (params) => {
    const responseData = await fetchData(endpoints.shareItemTemplate, params);
    return responseData;
  },
  shareItemTemplateToSdc: async (params) => {
    const responseData = await fetchData(endpoints.shareItemTemplateToSdc, params);
    return responseData;
  },
  getSharingInfoByTxid: async (params) => {
    const responseData = await fetchData(endpoints.getSharingInfoByTxid, params);
    return responseData;
  },
  getSendDataCount: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getSendDataCount, params, signal);
    return responseData;
  },
  getSharingCountByItemid: async (params) => {
    const responseData = await fetchData(endpoints.getSharingCountByItemid, params);
    return responseData;
  },
  getSharedEntries: async (params) => {
    const responseData = await fetchData(endpoints.getSharedEntries, params);
    return responseData;
  },
  editShareItem: async (params) => {
    const responseData = await fetchData(endpoints.editShareItem, params);
    return responseData;
    // fetchData('PATCH', paths["editShareItem"] + params.shareId, params.data)
  },
  deleteShareItem: async (params) => {
    const responseData = await fetchData(endpoints.deleteShareItem, params);
    return responseData;
    // fetchData('DELETE', paths["deleteShareItem"] + params.shareId)
  },
  // templates
  getTemplates: async (params) => {
    const responseData = await fetchData(endpoints.getTemplates, params);
    return responseData;
  },
  saveTemplate: async (params) => {
    const responseData = await fetchData(endpoints.saveTemplate, params);
    return responseData;
  },
  getTemplateByDisplayName: async (params) => {
    const responseData = await fetchData(endpoints.getTemplateByDisplayName, params);
    return responseData;
    // fetchData('GET', paths["getTemplateByDisplayName"] + params.displayName)
  },
  filterTemplateByDisplayName: async (params) => {
    const responseData = await fetchData(endpoints.filterTemplateByDisplayName, params);
    return responseData;
  },
  deleteTemplateByDisplayName: async (params) => {
    const responseData = await fetchData(endpoints.deleteTemplateByDisplayName, params);
    return responseData;
    // fetchData('DELETE', paths["deleteTemplateByDisplayName"] + params.displayName)
  },
  // data templates
  getDataTemplates: async (params) => {
    const responseData = await fetchData(endpoints.getDataTemplates, params);
    return responseData;
  },
  postDataTemplate: async (params) => {
    const responseData = await fetchData(endpoints.postDataTemplate, params);
    return responseData;
  },
  updateDataTemplate: async (params) => {
    const responseData = await fetchData(endpoints.updateDataTemplate, params);
    return responseData;
  },
  deleteDataTemplate: async (params) => {
    const responseData = await fetchData(endpoints.deleteDataTemplate, params);
    return responseData;
  },
  uploadImage: async (params) => {
    const responseData = await fetchData(endpoints.uploadImage, params);
    return responseData;
  },
  subscribe: async (params) => {
    const responseData = await fetchData(endpoints.subscribe, params);
    return responseData;
  },
  unsubscribe: async (params) => {
    const responseData = await fetchData(endpoints.unsubscribe, params);
    return responseData;
  },
  getsubscriptions: async (params) => {
    const responseData = await fetchData(endpoints.getsubscriptions, params);
    return responseData;
  },
  // SDC
  getSendData: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getSendData, params, signal);
    return responseData;
  },
  getPublicShareUser: async (params) => {
    const responseData = await fetchData(endpoints.getPublicShareUser, params);
    return responseData;
  },
  uploadFile: async (params) => {
    const responseData = await fetchData(endpoints.uploadFile, params);
    return responseData;
  },
  uploadForm: async (params) => {
    const responseData = await fetchData(endpoints.uploadForm, params);
    return responseData;
  },
  uploadFileBase64: async (params) => {
    const responseData = await fetchData(endpoints.uploadFileBase64, params);
    return responseData;
  },
  getLatestJobStatus: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getLatestJobStatus, params, signal);
    return responseData;
  },
  getDocumentDetails: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getDocumentDetails, params, signal);
    return responseData;
  },
  deleteUploadedFile: async (params) => {
    const responseData = await fetchData(endpoints.deleteUploadedFile, params);
    return responseData;
  },
  generateSignature: async (params) => {
    const responseData = await fetchData(endpoints.generateSignature, params);
    return responseData;
  },
  generateReport: async (params) => {
    const responseData = await fetchData(endpoints.generateReport, params);
    return responseData;
  },
  getAccessRights: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getAccessRights, params, signal);
    return responseData;
  },
  getAccessRightsCount: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getAccessRightsCount, params, signal);
    return responseData;
  },
  addAccessRights: async (params) => {
    const responseData = await fetchData(endpoints.addAccessRights, params);
    return responseData;
  },
  accessRightsSendEmail: async (params) => {
    const responseData = await fetchData(endpoints.accessRightsSendEmail, params);
    return responseData;
  },
  deleteAccessRights: async (params) => {
    const responseData = await fetchData(endpoints.deleteAccessRights, params);
    return responseData;
  },
  prepareFileForDownload: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.prepareFileForDownload, params, signal);
    return responseData;
  },
  downloadFile: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.downloadFile, params, signal);
    return responseData;
  },
  downloadBase64File: async (params) => {
    const responseData = await fetchData(endpoints.downloadBase64File, params);
    return responseData;
  },
  downloadBase64Report: async (params) => {
    const responseData = await fetchData(endpoints.downloadBase64Report, params);
    return responseData;
  },
  getPackageDetails: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getPackageDetails, params, signal);
    return responseData;
  },
  getFormDetails: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getFormDetails, params, signal);
    return responseData;
  },
  checkValidPaymail: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.checkValidPaymail, params, signal, false, false);
    return responseData;
  },
  getLastLocations: async (params) => {
    const responseData = await fetchData(endpoints.getLastLocations, params);
    return responseData;
  },
  getPricingPlans: async (params) => {
    const responseData = await fetchData(endpoints.getPricingPlans, params);
    return responseData;
  },
  getUserSubscriptions: async (params) => {
    const responseData = await fetchData(endpoints.getUserSubscriptions, params);
    return responseData;
  },
  cancelUserSubscription: async (params) => {
    const responseData = await fetchData(endpoints.cancelUserSubscription, params);
    return responseData;
  },
  requestPricingPlanUpdate: async (params) => {
    const responseData = await fetchData(endpoints.requestPricingPlanUpdate, params);
    return responseData;
  },
  generate2FASecret: async (params) => {
    const responseData = await fetchData(endpoints.generate2FASecret, params);
    return responseData;
  },
  enable2FA: async (params) => {
    const responseData = await fetchData(endpoints.enable2FA, params);
    return responseData;
  },
  disable2FA: async (params) => {
    const responseData = await fetchData(endpoints.disable2FA, params);
    return responseData;
  },
  resetPassword: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.resetPassword, params, signal, false, false);
    return responseData;
  },
  resetUserPassword: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.resetUserPassword, params, signal, false, false);
    return responseData;
  },
  sendValidateEmailLink: async (params) => {
    const responseData = await fetchData(endpoints.sendValidateEmailLink, params);
    return responseData;
  },
  createUser: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.createUser, params, signal, false, true);
    return responseData;
  },
  getUserBillings: async (params) => {
    const responseData = await fetchData(endpoints.getUserBillings, params);
    return responseData;
  },
  getUserServices: async (params) => {
    const responseData = await fetchData(endpoints.getUserServices, params);
    return responseData;
  },
  logoutUserAllSessions: async (params) => {
    const responseData = await fetchData(endpoints.logoutUserAllSessions, params);
    return responseData;
  },
  updateUser: async (params) => {
    const responseData = await fetchData(endpoints.updateUser, params);
    return responseData;
  },
  deleteUser: async (params) => {
    const responseData = await fetchData(endpoints.deleteUser, params);
    return responseData;
  },
  getAvailableOrganizations: async (params) => {
    const responseData = await fetchData(endpoints.getAvailableOrganizations, params, false);
    return responseData;
  },
  getBatchLookup: async (params) => {
    const responseData = await fetchData(endpoints.getBatchLookup, params);
    return responseData;
  },
  getSingleLookup: async (params) => {
    const responseData = await fetchData(endpoints.getSingleLookup, params);
    return responseData;
  },
  upsertLookup: async (params) => {
    const responseData = await fetchData(endpoints.upsertLookup, params);
    return responseData;
  },
  getUserDocuments: async (params) => {
    const responseData = await fetchData(endpoints.getUserDocuments, params);
    return responseData;
  },
  upsertUserImage: async (params) => {
    const responseData = await fetchData(endpoints.upsertUserImage, params);
    return responseData;
  },
  upsertUserImageBase64: async (params) => {
    const responseData = await fetchData(endpoints.upsertUserImageBase64, params);
    return responseData;
  },
  upsertUserFile: async (params) => {
    const responseData = await fetchData(endpoints.upsertUserFile, params);
    return responseData;
  },
  upsertUserFileBase64: async (params) => {
    const responseData = await fetchData(endpoints.upsertUserFileBase64, params);
    return responseData;
  },
  readUserDocument: async (params) => {
    const responseData = await fetchData(endpoints.readUserDocument, params);
    return responseData;
  },
  deleteUserDocument: async (params) => {
    const responseData = await fetchData(endpoints.deleteUserDocument, params);
    return responseData;
  },
  requestDocumentsValidation: async (params) => {
    const responseData = await fetchData(endpoints.requestDocumentsValidation, params);
    return responseData;
  },
  getAsfis: async (params) => {
    const responseData = await fetchData(endpoints.getAsfis, params);
    return responseData;
  },
  getLca: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getLca, params, signal);
    return responseData;
  },
  saveLca: async (params) => {
    const responseData = await fetchData(endpoints.saveLca, params);
    return responseData;
  },
  updateLca: async (params) => {
    const responseData = await fetchData(endpoints.updateLca, params);
    return responseData;
  },
  deleteLca: async (params) => {
    const responseData = await fetchData(endpoints.deleteLca, params);
    return responseData;
  },
  elkSearch: async (params) => {
    const responseData = await fetchData(endpoints.elkSearch, params);
    return responseData;
  },
  downloadReport: async (params) => {
    const responseData = await fetchData(endpoints.downloadReport, params);
    return responseData;
  },
  fetchDid: async (params) => {
    const responseData = await fetchData(endpoints.fetchDid, params);
    return responseData;
  },
  uploadLookupImage: async (params) => {
    const responseData = await fetchData(endpoints.uploadLookupImage, params);
    return responseData;
  },
  uploadLookupImageBase64: async (params) => {
    const responseData = await fetchData(endpoints.uploadLookupImageBase64, params);
    return responseData;
  },
  upsertPublicLookup: async (params, signal = undefined) => {
    const responseData = await fetchData(
      endpoints.upsertPublicLookup,
      params,
      signal,
      false,
      false
    );
    return responseData;
  },
  getDeployments: async (params, signal = undefined) => {
    const responseData = await fetchData(endpoints.getDeployments, params);
    return responseData;
  },
  getSinglePublicLookup: async (params, signal = undefined) => {
    const responseData = await fetchData(
      endpoints.getSinglePublicLookup,
      params,
      signal,
      false,
      false
    );
    return responseData;
  },
  productCertificate: async (params) => {
    const responseData = await fetchData(endpoints.productCertificate, params, false, false);
    return responseData;
  },
  snapshotCert: async (params) => {
    const responseData = await fetchData(endpoints.snapshotCert, params, false, false);
    return responseData;
  },
  getUserExists: async (params) => {
    const responseData = await fetchData(endpoints.getUserExists, params);
    return responseData;
  },
  createGuestUser: async (params) => {
    const responseData = await fetchData(endpoints.createGuestUser, params);
    return responseData;
  },
  inviteAccessRights: async (params) => {
    const responseData = await fetchData(endpoints.inviteAccessRights, params);
    return responseData;
  },
  addSignature: async (params) => {
    const responseData = await fetchData(endpoints.addSignature, params);
    return responseData;
  },
  getIotConnections: async (params) => {
    const responseData = await fetchData(endpoints.getIotConnections, params);
    return responseData;
  },
  getIotSettings: async (params) => {
    const responseData = await fetchData(endpoints.getIotSettings, params);
    return responseData;
  },
  getTasks: async (params) => {
    const responseData = await fetchData(endpoints.getTasks, params);
    return responseData;
  },
  getTaskTemplates: async (params) => {
    const responseData = await fetchData(endpoints.getTaskTemplates, params);
    return responseData;
  },
  createTaskTemplate: async (params) => {
    const responseData = await fetchData(endpoints.createTaskTemplate, params);
    return responseData;
  },
  editTaskTemplate: async (params) => {
    const responseData = await fetchData(endpoints.editTaskTemplate, params);
    return responseData;
  },
  deleteTaskTemplate: async (params) => {
    const responseData = await fetchData(endpoints.deleteTaskTemplate, params);
    return responseData;
  },
  createJobTemplate: async (params) => {
    const responseData = await fetchData(endpoints.createJobTemplate, params);
    return responseData;
  },
  deleteJobTemplate: async (params) => {
    const responseData = await fetchData(endpoints.deleteJobTemplate, params);
    return responseData;
  },
  updateJobTemplate: async (params) => {
    const responseData = await fetchData(endpoints.updateJobTemplate, params);
    return responseData;
  },
  addTaskTemplateToJobTemplate: async (params) => {
    const responseData = await fetchData(endpoints.addTaskTemplateToJobTemplate, params);
    return responseData;
  },
  removeTaskTemplateFromJobTemplate: async (params) => {
    const responseData = await fetchData(endpoints.removeTaskTemplateFromJobTemplate, params);
    return responseData;
  },
  getJobTemplates: async (params) => {
    const responseData = await fetchData(endpoints.getJobTemplates, params);
    return responseData;
  },
  getJobs: async (params) => {
    const responseData = await fetchData(endpoints.getJobs, params);
    return responseData;
  },
  getSingleJob: async (params) => {
    const responseData = await fetchData(endpoints.getSingleJob, params);
    return responseData;
  },
  createJob: async (params) => {
    const responseData = await fetchData(endpoints.createJob, params);
    return responseData;
  },
  deleteJob: async (params) => {
    const responseData = await fetchData(endpoints.deleteJob, params);
    return responseData;
  },
  updateJob: async (params) => {
    const responseData = await fetchData(endpoints.updateJob, params);
    return responseData;
  },
  updateTask: async (params) => {
    const responseData = await fetchData(endpoints.updateTask, params);
    return responseData;
  },
  queryLatestJobStatus: async (params, signal = undefined) => {
    const responseData = await queryLatestJobStatus(endpoints.getLatestJobStatus, params, signal);
    return responseData;
  },
  setApiStorage,
  getRedirectUri,
  documentTypes,
  documentCriteria,
  jobStatus,
  provenanceFrontendURL,
  dcaFrontendURL,
  capabilities,
  isCapable,
  companyNames,
  companyName,
  environmentEnum,
  environment,
  setEnvironment,
  getEnvironment,
  showRegisterPage,
  ID,
  defaultRegistrationServices,
  defaultRegistrationRoles,
  identifiersUrl,
  nkcUrl,
};

export default APIService;
